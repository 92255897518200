import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Rewrite From Backbone to React – Best Practices",
  "author": "tzookb",
  "type": "post",
  "date": "2019-08-31T16:24:11.000Z",
  "url": "/rewrite-from-backbone-to-react-best-practices",
  "categories": ["How To", "javascript", "My Projects"],
  "tags": ["backbone", "javascript", "react", "rewrite"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "./boom.png",
        "alt": "my image"
      }}></img></p>
    <p>{`Tech is moving fast! especially in the Javascript world. There are so many new tools and frameworks popping up, that literally in a year or two you can be using `}{`“`}{`legacy`}{`”`}{` stack. That`}{`’`}{`s true and not true in the same time. There are some technologies who probably will last more than a year or two but just wanted to empahsize on the world we live in. We had our project that was build on our old friend `}{`“`}{`backbone`}{`”`}{`, the app has many pages and features. Surely some of the are quite complex with many different options and capabilities. The idea to move to react started as we noticed it was quite hard to maintain the old code, add new features and the worst of all`}{`…`}{` Find great people! Most devs always enjoy building stuff with the `}{`“`}{`cool kid`}{`”`}{` in the block in the tech world. It is surely not a reason to change technologies. But when its hard to find great talent, that surely should run on a red light for the team. `}</p>
    <h3>{`The `}{`“`}{`rewrite`}{`”`}{` options:`}</h3>
    <h4>{`Write From Scratch That may sound as the best option at first. But 99% of the times your estimates will be way off and you will get stuck for a long with the need of supporting two `}{`“`}{`apps`}{`”`}{` at once. You may `}{`“`}{`hold off`}{`”`}{` with new features on the legacy app, but as time goes by and the `}{`“`}{`new app`}{`”`}{` is not production ready. You will start feeling the pressure of the need to provide functionality to the legacy app. If you have a relative `}{`“`}{`small-medium`}{`”`}{` app be sure to plan way in advance what is needed before going in this approach. It`}{`’`}{`s not fun building two apps at once.`}</h4>
    <h4>{`Dual App This approach has more `}{`“`}{`wiggle`}{`”`}{` room. You decide to use your legacy app and start building new features inside it with the new tech stack. In this way you are not forced on providing features in two different apps as its the same one. As you progress and you have other features extended you can plan out and rewrite that specific feature in the new tech stack. You may get stuck for a long time with old stack in some places but probably those are spots that has less `}{`“`}{`traction`}{`”`}{` or require a lot of time to rewrite. Be sure if you go on this route to have some plans and goals. As the move to the new stack can literally never get `}{`“`}{`finished`}{`”`}{` as you have no pressure changing parts of the app as the `}{`“`}{`just work`}{`”`}{` and the refactor work will always get backlogged as there will be other more urgent items.`}</h4>
    <h3>{`What We did? We went with the second approach, you probably felt that with my writing above, so I`}{`’`}{`m quite unbiased 🙂 But we surely had the discussion on which approach to take and luckily we went with that route. We create new routes inside backbone that will load a react app inside the backbone template. Inside that page backbone wont do anything. React will handle the rest and all the `}{`“`}{`internal`}{`”`}{` routing`}{`”`}{`. When user routed out off that react part, backbone will get back to action. Users wouldn`}{`’`}{`t have any change in their UX and we can provide better tools and much faster.`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      